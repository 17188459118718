.product-tags-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--Gap-gap-8, 8px);
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;

    .product-tags {
        font-size: 0.75rem;
        font-family: var(--skin-body-font-bold);
        padding: 4px 8px;
        border-radius: 100px;
        text-transform: uppercase;

        &.out-of-stock {
            font-size: 16px;
            letter-spacing: -0.32px;
            @include primaryFont(700);
            color: #FF1310;
            background: #FFD8D8;
            padding: 2px 8px 0px;
        }
    }
}