.btn {
    height: $btn-height;
    border-radius: $btn-radius;
    padding: 0 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    font-size: 14px;
    line-height: 12.6px;
    letter-spacing: -0.02em;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    @include primaryFont(300);
    transition: all ease-in 0.2s;
    z-index: 1;

    &:focus{
        box-shadow: none;
    }

    &:before {
        content: "";
        top: 0;
        width: 100%;
        height: 100%;
        left: -100%;
        position: absolute;
        transition: all 0.1s ease-in-out;
        z-index: -1;
    }

    &:hover {
        &::before {
            left: 0;
        }
    }


    &.btn-primary {
        color: var(--skin-link-color-2);
        background: var(--skin-link-color-hover-1);

        &:before {
            background: var(--skin-primary-color-invert-1);
        }

        &:hover {
            background: var(--skin-link-color-hover-1);
            color: var(--skin-link-color-2);
            text-decoration: none;
        }

        &.added {
            background-color: var(--skin-primary-color-3);
            color: var(--skin-link-color-3);

            &:before {
                background-color: var(--skin-primary-color-invert-3);
            }
    
            &:hover {
                background-color: var(--skin-primary-color-3);
                color: var(--skin-link-color-3);
            }
        }
    }

    &.btn-secondary {
        background-color: var(--skin-primary-color-2);
        color: var(--skin-link-color-2);
        
        &:before {
            background-color: var(--skin-primary-color-invert-2);
        }

        &:hover {
            background-color: var(--skin-primary-color-2);
            color: var(--skin-link-color-2);
        }
    }

    &.btn-tertiary {
        background-color: var(--skin-primary-color-3);
        color: var(--skin-link-color-3);

        &:before {
            background-color: var(--skin-primary-color-invert-3);
        }

        &:hover {
            background-color: var(--skin-primary-color-3);
            color: var(--skin-link-color-3);
        }
    }

    // &.btn-tertiary {
    //     background-color: var(--skin-primary-color-2);
    //     color: var(--skin-link-color-2);

    //     &:hover {
    //         color: var(--skin-link-color-2);
    //         background-color: var(--skin-primary-color-invert-2);
    //     }
    // }

    &.btn-white {
        background-color: $white;
        color: var(--skin-primary-color-2);

        &:before {
            background-color: var(--skin-primary-color-invert-3);
        }

        &:hover {
            color: var(--skin-primary-color-2);
        }
    }

    &.btn-link {
        padding-left: 0;
        padding-right: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        height: auto;
        color: $plum;
        font-size: 14px;
        line-height: 12.6px;
        letter-spacing: -0.02em;
        text-decoration: underline;
        font-family: var(--skin-body-font-book);
        
        &:hover {
            color: var(--skin-link-color-1);
        }
    }

    &.btn-outline-primary {
        border-width: 1px;
        border-style: solid;

        &:hover {
            color: var(--skin-link-color-2);
            background-color: var(--skin-link-color-hover-1);
            border-color: var(--skin-link-color-hover-1);
        }

        &:active,
        &:focus {
            outline: 0 !important;
            box-shadow: none;

        }

        
        &:not(:disabled),
        &:not(.disabled) {
            &.active {
                background-color: var(--skin-link-color-hover-1);
                border-color: var(--skin-link-color-hover-1);
                outline: 0;
                box-shadow: none;
            }
        }
    }

    &.btn-outline-secondary  {
        border-width: 1px;
        border-style: solid;
    }

    &.btn-rounded {
        border-radius: 100px
    }

    &.btn-text {
        border-radius: 0;
    }
}

.button-link {
    font-size: 14px;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    @include primaryFont(400);
    background: none;
    border: 0;
    text-decoration: underline;
    color: get-brand-color(plum);
}

.css-add-to-wish-list {
    width: 54px;
    height: 54px;
    background: #F7F3EF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 54px;

    &.added {
        svg {
            path {
                fill: rgb(110, 34, 78)
            }
        }
    }
}

.link-primary {
    text-decoration: underline;
    @include primaryFont(400);
    font-size: 16px;
    letter-spacing: -0.16px;
}

button.close {
    span {
        font-size: 0;
        &:before {
            content: '';
            background: url("../../images/icons/close.svg") no-repeat;
            width: 1rem;
            height: 1rem;
            display: block;
        }
    }
}
