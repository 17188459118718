.baseFontSize {
    font-size: $font-size-base
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    @include headingFont();
    line-height: 1em;

    &.base {
        @extend .baseFontSize;
    }
    &.baseFont{
        @include primaryFont(400);
    }

}

h1, .h1 {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.06em;

    &.large {
        font-size: 72px;
        letter-spacing: -0.06em;
    }

    &.small {
        font-size: 48px;
        letter-spacing: -0.06em;
    }

    &.page-title {
        margin-top: 25px;
        margin-bottom: 0;
    }
}

.error-hero {
    .page-title {
        text-align: center;
        margin: 60px 0 20px;
    }
}

h2,.h2 {
    font-size: 48px;
    letter-spacing: -0.06em;

    &.large {
        font-size: 60px;
        letter-spacing: -0.06em;
    }

    &.small {
        font-size: 36px;
        letter-spacing: -0.05em;
    }
}

h3,.h3 {
    font-size: 36px;
    letter-spacing: -0.05em;

    &.large {
        font-size: 48px;
        letter-spacing: -0.06em;
    }

    &.small {
        font-size: 30px;
        letter-spacing: -0.04em;
    }
}

h4,.h4 {
    font-size: 30px;
    letter-spacing: -0.04em;

    &.large {
        font-size: 36px;
        letter-spacing: -0.05em;
    }

    &.small {
        font-size: 24px;
        letter-spacing: -0.04em;
    }

    
}

h5,.h5 {
    font-size: 24px;
    letter-spacing: -0.04em;

    &.large {
        font-size: 30px;
        letter-spacing: -0.04em;
    }

    &.small {
        font-size: 20px;
        letter-spacing: -0.04em;
    }
}

h6,.h6 {
    font-size: 20px;
    letter-spacing: -0.04em;

    &.large {
        font-size: 24px;
        letter-spacing: -0.04em;
    }

    &.small {
        font-size: 18px;
        letter-spacing: -0.04em;
    }
}

p {
    margin: 0 0 20px;
    @extend .baseFontSize;
    @extend .paragraph-base;
}

a {
    &:hover {
        text-decoration: none;
    }
}

.uppercaseText {
    text-transform: uppercase;
}

.function {
    line-height: 20px;
    letter-spacing: -0.02em;
    @extend .baseFontSize;
}

.text-btn {
    font-size: 14px;
    line-height: 12.6px;
    letter-spacing: -0.02em;
    text-decoration: none;
    @include primaryFont(300);
}

.text-link {
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
    letter-spacing: -0.02em;
    padding-bottom: 3px;
    border-bottom: 2px solid var(--skin-link-color-1);
    @include primaryFont(400);
}

.text-input {
    line-height: 12.6px;
    letter-spacing: -0.02em;
    @extend .baseFontSize;
}

.product-description {
    line-height: 19.2px;
    letter-spacing: -0.02em;
    @extend .baseFontSize;
    @include primaryFont(700);

    @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 16.8px;
    }
}

.paragraph-lg {
    line-height: 25.2px;
    letter-spacing: -0.04em;
    font-size: 18px;
}

.paragraph-base {
    line-height: 22.4px;
    letter-spacing: -0.02em;
    @extend .baseFontSize;
}

.paragraph-sm {
    line-height: 19.6px;
    font-size: 14px;
    letter-spacing: -0.02em;
}

.text-label {
    letter-spacing: -0.02em;
    font-size: 12px;
    @include primaryFont(700);
    @extend .uppercaseText;
}

.text-price {
    line-height: 16px;
    letter-spacing: -0.02em;
    @extend .baseFontSize;

    &.original {
        text-decoration: line-through;
    }
}
