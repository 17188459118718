.side-panels {
    position: fixed;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(#1c1c1e, 0);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 4000;
    opacity: 0;
    transition: opacity 0.2s ease-out;

    @include media-breakpoint-down(sm) {
        left: 0;
        right: 0;
        bottom: 100%;
    }

    &.show-module {
        opacity: 1;
        left: 0;
        background: rgba(0, 0, 0, 0.5);

        @include media-breakpoint-down(sm) {
            bottom: 0;
        }

        .module-section {
            right: 0;

            @include media-breakpoint-down(sm) {
                bottom: 0;
            }
        }

    }

    .module-section {
        position: absolute;
        right: -150%;
        top: 0;
        max-width: 28.375rem;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 1.5rem 0rem 0rem 1.5rem;
        background: white;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
        transition: all .4s ease;
        overflow-y: scroll;
        scrollbar-width: none;

        @include media-breakpoint-down(sm) {
            max-width: inherit;
            border-radius: 1.5rem 1.5rem 0 0;
            height: auto;
            bottom: -150%;
            left: 0;
            right: 0;
            top: auto;
            max-height: 600px;
        }
    }

    .module-content {
        max-height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        padding: 20px 24px 0;
        overflow: auto;
    }

    &.js-mini-cart-module {
        .module-content {
            -ms-overflow-style: none;
            scrollbar-width: none;
            /* stylelint-disable-line property-no-unknown */

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .custom-tab-pane {
        height: 100%;
        display: none;

        &.active {
            display: block;
        }
    }

    header {
        display: flex;
        align-items: center;
        margin: 0 0 16px;
        gap: 12px;
        height: 40px;

        h3 {
            margin: 0;
            color: $grey9;
            font-size: 1.125rem;
            line-height: 120%;
            letter-spacing: -0.045rem;
            margin-top: 2px;
            @include primaryFont(400);
        }

        .header-icon {
            display: block;
            padding: 8px;
        }
    }

    .body-section {
        padding: 8px 0 16px;

        .module-section-title {
            margin: 0 0 12px;
            font-size: toRem(24);
            letter-spacing: -0.01rem;
            color: $grey9;
            @include headingFont();
            line-height: 110%;
            /* 1.65rem */
            letter-spacing: -0.06rem;

            &.small {
                @include primaryFont(400);
                color: #1e1e1e;
            }
        }

        .forgot-password {
            #password-reset {
                @include primaryFont(400);
                color: #1e1e1e;
                letter-spacing: -0.28px;

                &:hover {
                    color: #1e1e1e;
                }
            }
        }

        .module-section-description {
            margin-bottom: 20px;
            @include primaryFont(350);
            color: $grey9;
            font-size: toRem(14);
            line-height: 130%;
            /* 18.2px */
            letter-spacing: -0.28px;
        }

        .feature-section {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;

            .feature-item {
                display: flex;
                flex-direction: column;
                max-width: 128px;
                gap: 7px;
                color: $sageGreen;
                text-align: center;
                font-size: toRem(11);
                line-height: 130%;
                /* 14.045px */
                letter-spacing: -0.216px;
                @include primaryFont(700);
            }
        }

        .form-control {
            border-radius: tpRem(6);
            border: 1px solid $grey3;
            background: white;
            height: toRem(43);
        }
    }

    h2 {
        font-size: toRem(24);
        line-height: 120%;
        margin: 0 0 8px;
    }

    .btn-outline-secondary {
        width: 100%;
        height: 42px;
        border-radius: toRem(100);
        font-size: toRem(14);
        letter-spacing: -0.28px;
        text-transform: uppercase;
        @include primaryFont(400);
        display: flex;
    }

    #password-reset {
        text-decoration: underline;
        font-size: toRem(14);
        line-height: 22px;
        color: #1e1e1e;
        line-height: 130%;
        /* 18.2px */
        letter-spacing: -0.28px;
        @include primaryFont(350);


        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }

    .field-icon {
        position: relative;

        input.form-control {
            padding-right: 50px;
        }
    }

    .icon-password-eye {
        width: 24px;
        height: 24px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 21px;
        transform: translateY(-50%);
    }

    .ri-eye-line {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z' stroke='%23A4A4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.45825 12C3.73253 7.94288 7.52281 5 12.0004 5C16.4781 5 20.2684 7.94291 21.5426 12C20.2684 16.0571 16.4781 19 12.0005 19C7.52281 19 3.73251 16.0571 2.45825 12Z' stroke='%23A4A4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    .ri-eye-off-line {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M3 3L6.58916 6.58916M21 21L17.4112 17.4112M13.8749 18.8246C13.2677 18.9398 12.6411 19 12.0005 19C7.52281 19 3.73251 16.0571 2.45825 12C2.80515 10.8955 3.33851 9.87361 4.02143 8.97118M9.87868 9.87868C10.4216 9.33579 11.1716 9 12 9C13.6569 9 15 10.3431 15 12C15 12.8284 14.6642 13.5784 14.1213 14.1213M9.87868 9.87868L14.1213 14.1213M9.87868 9.87868L6.58916 6.58916M14.1213 14.1213L6.58916 6.58916M14.1213 14.1213L17.4112 17.4112M6.58916 6.58916C8.14898 5.58354 10.0066 5 12.0004 5C16.4781 5 20.2684 7.94291 21.5426 12C20.8357 14.2507 19.3545 16.1585 17.4112 17.4112' stroke='%23A4A4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }


    .yotpo-points {
        .points-block {
            width: 48%;
            border-radius: 100px;
            background: #F5F7F3;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            color: $grey9;
            font-size: 18px;
            line-height: 21.6px;
            letter-spacing: -0.72px;
            @include primaryFont(400);
        }

        .redeem-btn {
            width: 48%;
            line-height: 14px;
        }
    }



    form {
        &.login,
        &.registration,
        &#registered-customer {
            button.btn-primary {
                margin-bottom: 12px;
                width: 100%;
                height: 42px;
                border-radius: toRem(100);
                font-size: toRem(14);
                letter-spacing: -0.28px;
                text-transform: uppercase;
                @include primaryFont(400);
                display: flex;
            }
        }
    }

    .guest-description {
        font-size: 14px;
        letter-spacing: -0.28px;
        margin: 12px 0 0;
        @include primaryFont(350);
    }

    .view-edit {
        color: $grey4;
        font-size: toRem(12);
        line-height: 100%;
        text-decoration: none;
        background: $grey1;
        border-radius: 6px;
        padding: 6px;
        display: inline-block;
        margin-left: 12px;
    }

    .module-footer {
        border-top: 1px solid #d8d8db;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: -15px;
        margin-right: -15px;
        padding-bottom: 4px;
        background: $grey1;

        &.no-bg {
            background: none;
            border-top: 0;
            padding-bottom: 20px;
        }
    }

    .module-close,
    .button.close {
        position: absolute;
        right: 20px;
        top: 24px;
        z-index: 1;
    }

    .dashboard-menu-navigation {
        .profile-menu-links {
            list-style-type: none;
            padding-left: 0;
            margin: 24px 0 0 0;

            .menu-link-item {
                font-size: 16px;
                letter-spacing: -0.32px;
                @include primaryFont(350);
                display: flex;
                gap: 8px;
                padding: 0 0 12px;
                margin: 12px 0;
                color: $grey9;
                align-items: center;
                border-bottom: 1px solid $hr-border-color;

                svg {
                    display: inline-block;
                }
            }
        }

        .logout-link-button {
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: -0.28px;
            @include primaryFont(400);
            color: $dark-gray;
            text-decoration: underline;
            padding: 0;
        }
    }

    .form-title {
        font-size: 18px;
        margin-bottom: 12px;
        @include primaryFont(400);
        letter-spacing: -0.72px;
    }

    .modal-header {
        .close {
            margin: 0;
        }
    }

    &.css-guest-checkout-module {
        .body-section {
            padding-bottom: 40px;
            .module-section-title {
                font-size: 20px;
            }
        }
    }
}

.modal-content {
    color: $grey9;

    .request-password-title {
        line-height: 48px;
    }
}


.modal.fade button.close {
    margin: 0 !important;
}