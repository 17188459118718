@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCard";

.minicart {
    position: relative;
    margin-right: 15px;

    .minicart-link {
        position: relative;
    }

    .minicart-quantity {
        display: flex;
        padding: 0 6px;
        height: 18px;
        justify-content: center;
        align-items: center;
        color: get-brand-color(sageGreen);
        border-radius: 50%;
        font-size: 12px;
        letter-spacing: -0.24px;
        background: get-brand-color(sage);
        position: absolute;
        right: -12px;
        top: -9px;
        @include primaryFont(700);
    }

    .side-panels {
        .body-section {
            padding-top: 0;
            padding-bottom: 0;
        }

        .product-summary {
            .card {
                border: 0;

                .line-item-top {
                    border-top: 1px solid #DDD;
                    padding-top: 12px;
                    border-bottom: 0;
                    border-left: 0;
                    border-right: 0;
                }
            }
        }

        .product-info {
            padding-left: 24px;
            padding-right: 24px;
        }

        .line-item-top {
            display: flex;
            

            .line-item-image {
                width: 68px;
                height: 68px;
                border-radius: 12px;
                overflow: hidden;
                margin-right: 12px;

                img {
                    width: 100%;
                }
            }

            .line-item-info {
                width: calc(100% - 80px);
            }

            .product-tags {
                font-size: 0.75rem;
                @include primaryFont(700);
                padding: 4px 8px;
                border-radius: 100px;
                text-transform: uppercase;
                display: inline-block;
            }

            .line-item-name {
                font-size: 16px;
                letter-spacing: -0.32px;
                line-height: 120%;
                @include primaryFont(700);
                color: $grey9;
                margin-bottom: 8px; 
            }
            .line-item-total-price {
                margin-bottom: 3px;
            }

            .line-item-actions {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;

                .line-item-promo {
                    position: absolute;
                    left: 0;
                    top: 80%;
                    font-size: 14px;
                    letter-spacing: -0.56px;
                    color: #536552;
                    @include primaryFont(700);
                }

                .line-item-price {
                    margin-right: 4px;

                    // .pricing {
                    //     font-size: 16px;
                    //     letter-spacing: -0.32px;
                    //     @include primaryFont(350);
                    // }


                    .price {
                        display: flex;
                        gap: 8px;
                        align-items: center;

                        font-size: 16px;
                        letter-spacing: -0.32px;
                        @include primaryFont(350);
                    }

                    .adjusted-price {
                        color: $plum;
                    }

                    .strike-through {
                        text-decoration: line-through;
                        color: #a4a4a4;
                    }
                }

                .line-item-attributes,
                .line-item-option {
                    font-size: 12px;
                    letter-spacing: -0.24px;
                    color: #7A7A7A;
                    white-space: nowrap;
                    @include primaryFont(350);
                }

                .line-item-gift-card {
                    .line-item-attributes {
                        font-size: 16px;
                        letter-spacing: -0.32px;
                        @include primaryFont(350);
                        color: #1e1e1e;
                        line-height: 100%;
                        margin-bottom: 8px;
                        
                        b {
                            @include primaryFont(400);
                        }
                    }

                    .edit-price {
                        .price {
                            font-size: 16px;
                            @include primaryFont(350);
                            line-height: 100%; /* 16px */
                            letter-spacing: -0.32px;
                        }
                    }
                }
            }

            .line-item-info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .line-item-qty-price-delete {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .line-item-qty {
                        margin-right: 8px;

                        label {
                            display: none;
                        }

                        .custom-select {
                            width: 62px;
                            height: 35px;
                            padding: 4px 12px;
                        }

                        .manual-quantity {
                            input {
                                width: 62px;
                                height: 35px;
                                padding: 4px 28px 4px 12px;
                            }

                            .manual-quantity-indicator {
                                padding-right: 15px;
                                width: 28px;
                                height: 19px;

                                &.manual-quantity-plus {
                                    padding-top: 12px;
                                }

                                &.manual-quantity-minus {
                                    padding-bottom: 12px;
                                }
                            }
                        }

                        &.disabled {
                            opacity: 0.5;

                            .manual-quantity {
                                pointer-events: none;
                            }
                        }
                    }

                    &.hideQty {
                        .line-item-qty {
                            .custom-select {
                                // display: none;
                            }
                        }
                    }
                }
            }
        }

        .card {
            &.product-info {
                margin-bottom: 0;
            }
        }
    }

    .minicart-footer {
        border-top: 1px solid #DDD;
        padding: 12px 24px 24px;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        background: #fff;
        z-index: 3;

        .estimated-total {
            margin-bottom: 12px;

            .sub-total {
                font-size: 20px;
                letter-spacing: -0.8px;
                line-height: 120%;
                @include primaryFont(700);
                margin: 0 0 4px;
            }
            .shipping-calculations {
                font-size: 12px;
                letter-spacing: -0.24px;
                line-height: 130%;
                margin-bottom: 12px;
                @include primaryFont(350);
            }
        }

        .minicart-checkout-cta {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;

            .mb-sm-3 {
                width: 58%;
                margin-bottom: 0 !important;
            }

            .checkout-btn  {
                width: calc((100% - 43%) - 12px);
                text-transform: uppercase;
            }

            .btn-outline-primary {
                width: 43%;
                color: $plum !important;
                border-color: $plum !important;
                margin-left: 12px;

                &:hover {
                    color: #fff !important
                }
            }

            .braintree-cart-paypal-buttons-wrap {
                margin-bottom: 0px;
                padding-bottom: 0;
            }
        }
    }

    .module-section {
        overflow: hidden;
    }

    .product-listing-lineItem {
        padding-bottom: 24px;
        height: calc(100vh - 284px);
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .module-content {
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
    }

    .project-main-container {
        background: #F7F3EF;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .module-header {
        padding-left: 24px;
        padding-right: 24px;
    }

    .project-head-container {
        position: relative;
    }

    .project-name-container {
        
        margin-bottom: 16px;

        .project-label {
            font-size: 14px;
            letter-spacing: -0.28px;
            line-height: 100%;
            text-transform: uppercase;
            @include primaryFont(400);
            margin: 0 0 4px;
            display: block;
        }

        .project-name {
            color: #6E224E;
            letter-spacing: -0.8px;
            font-size: 20px;
            line-height: 100%;
            margin-bottom: 4px;
            @include primaryFont(400);
            display: block;
        }

        .project-items-count {
            letter-spacing: -0.64px;
            color: #A4A4A4;
            font-size: 16px;
        }
    }

    .delete-project-container {
        position: absolute;
        right: 0;
        top: 47px;
        .delete-project-cus {
            letter-spacing: -0.24px;
            text-decoration-line: underline;
            color: #7A7A7A;
            @include primaryFont(350);
            font-size: 12px;
            cursor: pointer;
        }
    }

    .project-item-container {
        margin-bottom: 16px;
    }

    .line-item-info-block {
        width: calc(100% - 96px);

        &.digitalGift {
            width: calc(100% - 38px);
        }
    }

    .line-item-name {
        text-overflow: inherit;
        white-space: normal;
    }

    .line-item-delete {
        .btn {
            margin-top: -16px;

            &:before {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .module-section {
            max-height: inherit;
        }

        .product-listing-lineItem {
            padding-bottom: 224px;
        }

        .module-content {
            margin-bottom: 0;
        }

        .side-panels {
            .body-section {
                .form-control {
                    height: 35px;
                    padding: 5px 12px;
                }
            }

            .line-item-top {
                .line-item-actions {
                    .line-item-promo {
                        @include primaryFont(600);
                        font-size: 11px;
                    }
                }
            }
        }
    }

    .form-group {
        &.custom-select-box-qty {
            &:after {
                top: 8px;
                display: none;
            }
        }
    }
}

.hide-link-med {

    @include media-breakpoint-only(sm) {
        display: none;
    }
}

.hide-no-link {

    @include media-breakpoint-up(md) {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}
