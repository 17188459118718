@import "../utilities/swatch";
//@import "productCommon";
@import "quickView";

.product-tile {
    border: 0;
    margin-bottom: 0;
    overflow: hidden;

    .swatches {
        a {
            text-decoration: none;
        }
    }

    .wishlistTile {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
    }

    .product-tags-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;

        .product-tags {
            font-size: 0.75rem;
            font-family: var(--skin-body-font-bold);
            padding: 4px 8px;
            border-radius: 100px;
            text-transform: uppercase;
        }
    }

    .image-container {
        position: relative;
        border-radius: 5px;
        z-index: 0;

        .quickview,
        .cta-product-tile {
            position: absolute;
            bottom: -3rem;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.25s ease-out;

            .icon-cart,
            .icon-cart-quickview {
                width: 17px;
                height: 17px;
                margin-bottom: 2px;
            }
            .icon {
                width: 17px;
                height: 17px;
                margin-bottom: 2px;
            }

            @include media-breakpoint-down(md) {
                transform: inherit;
                bottom: 6px;
                left: 6px;
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon-cart-quickview {
                    margin-bottom: 0;
                }

                .button-label {
                    display: none;
                }
            }
        }

        &:hover {
            .quickview,
            .cta-product-tile {
                bottom: 1rem;
            }
        }

        a {
            display: block;

            .tile-image {
                width: 100%;
                border-radius: 10px;
            }
        }
    }

    .tile-body {
        padding: 0.625em 0 1.875em;
        position: relative;
        z-index: 2;
        background: $white;

        .color-swatches {
            min-height: 2.25em;

            .product-tile-color-label {
                cursor: pointer;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .price {
            margin-bottom: 0;
            font-weight: 350;
            letter-spacing: -0.32px;

            &:before {
                content: "Start";
                font-size: 12px;
            }

            @include media-breakpoint-down(md) {
                font-size: 1.0625em;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1em;
            }

            span {
                &.reduced-price {
                    color: #B84B65;
                }
            }

            del {
                font-size: 16px;
                line-height: 16px;
                color: #A4A4A4;
                letter-spacing: -0.16px;
                @include primaryFont(350);
            }
            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: bold;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .pdp-link {
            line-height: 1.2;
            font-family: var(--skin-body-font-bold);
            letter-spacing: -0.32px;

            a {
                font-size: 1em;
                text-decoration: none;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .ratings {
            font-size: 0.9em;
        }
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(1.8em, $white);
    }
}

/* stylelint-disable-next-line selector-id-pattern */
// #chooseBonusProductModal {
//     .modal-footer {
//         .container {
//             margin-left: 0;
//             width: 100%;
//             margin-right: 0;
//         }
//     }

//     .select-cbp-container {
//         margin-top: auto;
//         margin-bottom: auto;
//     }

//     .product-name-wrapper {
//         width: 100%;
//     }

//     .bonus-quantity,
//     .bonus-option {
//         margin-top: 0.938em;
//     }

//     .bonus-quantity-select {
//         min-width: 5em;
//     }

//     .select-bonus-product {
//         margin-top: 1em;
//     }

//     .selected-pid {
//         border: 1px solid $grey3;

//         .bonus-product-name {
//             white-space: nowrap;
//             overflow: hidden;
//             text-overflow: ellipsis;
//         }
//     }

//     .bonus-product-price {
//         text-align: center;
//         margin-top: 1em;
//     }
// }

.yotpo-widget-instance {
    .yotpo-sr-bottom-line-summary {
        @include media-breakpoint-down(lg) {
            flex-direction: column !important;
            .yotpo-sr-vertical-border {
                display: none !important;
            }
            .yotpo-sr-bottom-line-text {
                margin-top: 0.5em;
            }
        }
    }
}

.product-tile {
    .yotpo-sr-bottom-line-summary {
        @include media-breakpoint-down(lg) {
            flex-direction: row !important;
            .yotpo-sr-vertical-border {
                display: block !important;
            }
            .yotpo-sr-bottom-line-text {
                margin-top: 3px;
                font-size: 12px  !important;
            }

            .yotpo-sr-bottom-line-score {
                font-size: 12px !important;
                margin-top: 3px;
            }

            .star-container {
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        @media (max-width: 375px) {
            flex-direction: row !important;

            .yotpo-sr-vertical-border {
                display: none !important;
            }
            .yotpo-sr-bottom-line-text {
                display: none !important;
                margin-top: 0;
            }
        }
    }
}

.choice-of-bonus-product {
    .product-images {
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 16px;

        img {
            width: 100%;
        }
    }

    .product-name {
        font-size: 16px;
        letter-spacing: -0.32px;
        @include primaryFont(700);
        line-height: 120%;
        margin: 0 0 16px;
    }

    .btn {
        padding: 0 24px;
        @include primaryFont(400);
    }

    @include media-breakpoint-down(sm) {
        .product-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 12px;
        }

        .product-images {
            width: 90px;
            margin-right: 12px;
            margin-bottom: 0;
        }

        .product-name {

        }

        .product-bonus-wrapper {
            width: calc(100% - 102px);
        }
        
    }
}