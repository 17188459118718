@import "../utilities/swatch";
@import "productCommon";

#editProductModal {
    padding: 0!important;

    .quick-view-footer {
        .quantity-wrapper {
            display: none;
        }

        .add-to-wrapper {
            width: 100% !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .modal-body {
        overflow: hidden;
    }

    .attribute {
        margin-top: 8px;

        &.quantity {
            display: none;
        }
    }

    .swatch {
        h6 {
            letter-spacing: -0.72px;
            font-size: 18px;
            @include primaryFont(400);
            margin-bottom: 0;
            margin-top: 12px;
        }
    }
}

.modal.fade {
    .quick-view-dialog {

        // .modal-header {
        //     display: none!important;
        // }

        .promotion-list {
            text-align: left;
            margin-bottom: 6px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .promotion-title {
                color: #536552 !important;
                font-size: 14px;
                letter-spacing: -0.28px;
                @include primaryFont(700);
                text-transform: uppercase;
        
                span {
                    color: #536552 !important;
                }
            }
        
            .info-icon {
                margin-top: -4px;
                margin-left: 3px;
                display: none;
            }
        }

        .modal-body {
            padding: 1.25rem 1.5rem;
            overflow: hidden;
            button.close {
                padding: 0.5rem;
                right: -15px;
                top: -15px;
            }
        }

        .product-detail {
            margin: 0;
        }

        .whats-this {
            display: none;
        }

        .swiper-button-next,
        .swiper-button-prev,
        .swiper-pagination {
            display: none;
        }

        .product-name {
            color: var(--color-grey9);
            text-overflow: ellipsis;
            font-family: var(--skin-body-font-bold);
            font-size: 20px;
            line-height: 120%; /* 24px */
            letter-spacing: -0.8px;
        }

        .primary-images {
            margin-bottom: 1rem;
            border-radius: 10px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }

        a.full-pdp-link {
            color: $plum;
            margin-top: 1px;
            &:after {
                content: '';
                border-bottom: 1px solid $plum;
                width: 100%;
                height: 1px;
                display: block;
                margin-top: 3px;
            }
        }

        .main-attributes {
            display: none;
        }

        .attributes {
            margin-top: 8px;
            .attribute {
                width: 100%;
                margin-bottom: 0;
            }
        }

        .quick-view-footer {
            .quantity-wrapper {
                height: 40px;
                label {
                    display: none;
                }

                input {
                    width: 100%;
                }
            }
            .add-to-wrapper {
                .update-cart-product-global,
                .add-to-cart-global {
                    text-transform: uppercase;
                    padding: 12px 24px;
                    height: 40px;
                    width: 100%;
                    margin: 0;
                    &:disabled {
                        color: $white;
                    }
                    svg {
                        width: 17px;
                        height: 17px;
                        margin-right: 0.5rem;
                        margin-bottom: 2px;
                    }
                }
            }
        }

        .modal-footer {
            display: none;
        }

        .swatch-circle {
            // $white will be replaced by color image background via Javascript
            @include swatch(2.5em, $white);
        }

        .prices {
            del {
                font-size: 16px;
                line-height: 16px;
                color: #A4A4A4;
                letter-spacing: -0.16px;
                @include primaryFont(350);
            }

            span {
                &.reduced-price {
                    color: #B84B65;
                }
            }
        }

        a[disabled] .swatch-circle {
            cursor: not-allowed;

            &.color-value.selected::after {
                background-color: $gray-700;
            }
        }

        .color-attribute {
            border: none;
            padding: 0;
            background: none;
        }

        .custom-variant-selection {
            list-style-type: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
            gap: 10px;
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        
            li {
                padding: 2px;
                border: 2px solid;
                border-color: #ddd;
                border-radius: 6px;
                padding: 8px 10px;
                text-align: center;
                letter-spacing: -0.28px;
                
                font-size: 14px;
                cursor: pointer;
                flex: 1 1 48%; /* Adjust 10px for margin or padding */
                 /* Half of 10px to create spacing around items */
                color: #7A7A7A;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                max-width: 48%;
                &.selected {
                    border-color: #1E1E1E;
                    color: #1E1E1E;
                }
        
                &.unselectable {
                    background: #F6F6F6;
                    border-color: #f6f6f6;
                    cursor: auto;
                }
        
                .normal-price {
                    margin-left: 5px;
                }
        
                .special-offer {
                    letter-spacing: -0.28px;
                    text-decoration-line: underline;
                    color: #536552 !important;
                    margin-left: 5px;
                    margin-top: -1px;
                    // flex: 1 1 100%;
                }

                .strike-price {
                    text-decoration-line: strikethrough;
                    letter-spacing: -0.28px;
                    font-size: 14px;
                    color: #C8C8C8;
                }
        
                &.disabled {
                    background: #F6F6F6;
                    border-color: #F6F6F6;
                    color: #7A7A7A;
                    opacity: 1;
                    cursor: auto;
                    .special-offer {
                        color: #536552 !important;
                    }
                }
        
                @include media-breakpoint-down(lg) {
                    max-width: 48%;
                    flex: 1 1 48%;
                }
            }
        }
        .quantity-select {
            display: flex;
            width: 73px;
            padding: 0px 28px 0px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: 0;
            // border-radius: 6px;
            // border: 1px solid $grey3;
            height: 43px;
            &:focus-visible {
                outline-color: $grey9;
            }

            @include media-breakpoint-down(md) {
                padding-left: 6px;
            }
        }

        .manual-quantity {
            .manual-quantity-indicator {
                padding-right: 15px;
                width: 28px;
                height: 23px;

                &.manual-quantity-plus {
                    padding-top: 16px;
                }

                &.manual-quantity-minus {
                    padding-bottom: 16px;
                }
            }
        }
        .cart-and-ipay {
            margin-bottom: 20px;
        }
    }
}

.css-oos-msg-container {
    .css-oos-msg {
        font-size: 18px;
        letter-spacing: -0.72px;
        line-height: 110%;
        color: #FF1310;
        margin-bottom: 5px;
        @include primaryFont(350); 
    }
}

