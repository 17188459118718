.container .breadcrumb {
    border-radius: 0;
    padding: 0;
}
ol,ul {
    &.breadcrumb {
        display: none;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            display: flex;
        }
    }
}
.breadcrumb-item {
    font-size: 0.75rem;
    a {
        text-transform: uppercase;
        font-size: 0.75rem;
    }
    span {
        color: $grey5;
    }
    + .breadcrumb-item::before {
        content: ''!important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M4.5 2.5L8 6L4.5 9.5' stroke='%231E1E1E' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        background-size: cover;
        width: 0.75rem;
        height: 0.75rem;
        padding: 0;
        margin-right: 0.5rem;
        position: relative;
        top: 2px;
    }
}

.breadcrumb-mobile {
    text-transform: uppercase;
    font-size: 0.75rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
        display: none;
    }
    a {
        display: flex;
        &:before {
            content: '';
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M7.5 9.5L4 6L7.5 2.5' stroke='%231E1E1E' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat no-repeat;
            background-position: center center;
            background-size: cover;
            width: 0.75rem;
            height: 0.75rem;
            padding: 0;
            margin-right: 0.5rem;
            position: relative;
            top: 2px;
        }
    }
}