body {
    color: get-brand-color(plum);
}

a,
button {
    &:hover,
    &:focus,
    &:active {
        outline: 0;
    }
}

html {
    overflow-x: hidden;
    overflow-y: scroll;
}
.pull-left {
    float: left;
}
.pull-right {
    float: right;
}

#maincontent {

    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
}

.scroll-lock {
    overflow: hidden;
}

.makeVisible {
    overflow: visible !important;

    @include media-breakpoint-up(xxl) {
        overflow: hidden !important;
    }
}

.experience-commerce_layouts-chooseYourCraftHomeLayout,
.experience-commerce_layouts-latestProjectsLayout {
    @include media-breakpoint-down(lg) {
        overflow: hidden !important;
    }
}
// Remove horizontal scroll
#homepage {
    @include media-breakpoint-up(xl) {
        overflow-x: hidden;
    }
}