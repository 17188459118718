#footercontent {
    background: #F7F3EF;
    position: relative;

    .footer-top-area {
        padding: 72px 0 52px;

        @include media-breakpoint-down(md) {
            padding: 0px;
        }
        
    }
    
    h6 {
        letter-spacing: -0.72px;
        font-size: 18px;
        margin-bottom: 12px;
        @include primaryFont(700);
    }

    p {
        margin-bottom: 12px;
    }

    .footer-container {
        justify-content: space-between;
        padding: 0 16px;

        @include media-breakpoint-down(md) {
            padding: 40px 14px 20px 14px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                letter-spacing: -0.5px;
                margin-bottom: 8px;
                word-break: break-word;

                a {
                    display: block;
                }
            }
        }

        .footer-support, .footer-about{
            @include media-breakpoint-down(md) {
                width: 40%;
            }
        }

        .footer-resources, .footer-customer-support{
            @include media-breakpoint-down(md) {
                width: 60%;
            }
        }
    }

    .footer-copy {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
            color: get-brand-color(plum);
            padding-top: 15px;
        }
        
        a {
            color: get-brand-color(plum);
            font-size: 12px; 
            letter-spacing: -0.24px;
            text-decoration: underline;
        }
    }

    .footer-copyright {
        padding: 0 0 40px;
    }
    #fileUploadForm{
        display: none;
    }

    .footer-social {
        padding-left: 79px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            li {
                margin-right: 30px;
                @include media-breakpoint-down(md) {
                    margin-right: 16px;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            padding-left: 0;
            margin-top: 32px;
        }
    }

    .footer-newsletter {
        padding-left: 78px;

        @include media-breakpoint-down(lg) {
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 72px;
        }

        @include media-breakpoint-down(md) {
            background-color: #ECE1D6;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        
        .content-asset {
            .btn {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }

    &::after {
        content: '';
        display: block;
        clear: both;
        position: absolute;
        background-color: #ECE1D6;
        right: 0;
        top: 0;
        width: 34%;
        bottom: 0;
        z-index: 1;

        @include media-breakpoint-down(lg) {
            width: 36%;
            display: none;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            height: 240px;
            left: 0;
            display: block;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }

        @include media-breakpoint-up(xxl) {
            width: 36.5vw;
        }

        @media (min-width: 2100px) {
            width: 39vw;
        }
        
        @media (min-width: 2400px) {
            width: 40vw;
        }

    }
}