@import "toastMessage";

.attribute {
    margin-top: 28px;

    label {
        display: block;
    }
}

.swatch a {
    text-decoration: none;
}

.primary-images {

    @include media-breakpoint-down(xs) {
        margin: 0;
        padding: 0;
    }
}

.prices-add-to-cart-actions {
    width: 100%;
    position: static;
    margin-top: 20px;
    bottom: 0;
    z-index: 50;
    padding-right: 0;
    padding-left: 0;
    left: 0;

    .price {
        text-align: center;
    }
}

.cart-and-ipay {
    text-align: center;

    @include media-breakpoint-only(xs) {
        padding-bottom: 26px;

        .btn {
            width: 98%;
            margin: 1%;
            display: block;
        }
    }
}

.add-to-cart-messages {
    @include toast-message;
}

.add-to-basket-alert {
    @include toast-alert;
}

.simple-quantity {
    margin-top: 0;

    input {
        width: 100%;
    }
}

.main-attributes {
    margin-top: 1em;
}

.size-chart {
    margin-top: 1.071em;
}

div.availability {
    margin-top: 1.071em;
}

.bundle-item {
    padding-bottom: 1em;
    border-bottom: 1px solid $hr-border-color;

    &:last-child {
        border-bottom: none;
    }
}

.product-detail {
    margin-top: 2em;
    margin-bottom: 2em;

    @include media-breakpoint-only(xs) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.product-option:not(:first-child) {
    margin-top: 1.071em;
}
