@import "bootstrap/scss/functions";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../../../fonts" !default;

// flag icons location in relation to target location of the css file.
// $flag-icons-path: "../fonts/flags" !default;
$white: #fff !default;
$black: #000 !default;
$blue: #0094FB !default;
$green: #009258 !default;
$orange: #FF7D00 !default;
$plum: #6E224E !default;
$sageGreen60: #2D312F !default;
$sageGreen: #536552 !default;
$sage: #CFD6C5 !default;
$lightSage: #ECEFE7 !default;
$mist: #F7F3EF !default;

$red: #FF1310 !default;
$success: $green !default;
$danger: $red !default;
$light-blue: #7ed0ee !default;

// Brand Colors
$brand-colors: (
    plum: $plum,
    sageGreen60: $sageGreen60,
    sageGreen: $sageGreen,
    sage: $sage,
    lightSage: $lightSage,
    mist: $mist,
);

// FeedBack Colors
$feedback-colors: (
    negative: $red,
    notice: $orange,
    positive: $green,
    informative: $blue,
);


// Consolidated values
$grey1: #F6F6F6 !default;
$grey2: #EAEAEA !default;
$grey3: #DDDDDD !default;
$grey4: #C8C8C8 !default;
$grey5: #A4A4A4 !default;
$grey6: #7A7A7A!default;
$grey7: #595959 !default;
$grey8: #3C3C3C !default;
$grey9: #1E1E1E !default;
$grey10: #0E0E0E !default;
$grey-transparent-1: rgba(0, 0, 0, 65%) !default;
$grey-transparent-2: rgba(0, 0, 0, 25%) !default;
$light-gray: $grey1 !default;
$slightly-darker-gray: $grey4 !default;
$dark-gray: $grey6 !default;
$darker-gray: $grey7 !default;
$horizontal-rule-grey: $grey3 !default;
$product-number-grey: $grey3 !default;
$horizontal-border-grey: $grey4 !default;
$menu-link: $grey6 !default;
$close-menu-bg: $grey2 !default;
$link-color: $dark-gray !default;
$hr-border-color: $grey3 !default;
$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 992px,
    xl: 1200px,
    xxl: 1800px
) !default;
$container-max-widths: (
    md: 720px,
    lg: 940px,
    xl: 1440px
) !default;
$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;
$state-danger-text: #fff !default;
$alert-success-text: #fff !default;

// Font sizes
$font-size-base: 1rem !default; // 16px

// Bootstrap overrides
$body-bg: $grey1 !default;
$card-cap-bg: $white !default;

// Tabs
$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-active-link-hover-bg: transparent !default;

// breadcrumb
$breadcrumb-bg: transparent !default;

// table border
$table-border-color: $grey3 !default;
$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;
$svg-arrow: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cpath d='M12.6668 6.5L8.00016 11.1667L3.3335 6.5' stroke='%231E1E1E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat right 0.75rem center/18px 17px !default;

$btn-height: 47px;
$primary-btn-bg: $plum;
$btn-radius: 100px;

$primary-site-color: $plum;

$zindex: (
    menu: 100,
    header: 1000
);