.veil {
    position: absolute;
    z-index: 1001;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: $black;
    }
}

$spinner-size: 40px;

.spinner {
    width: $spinner-size;
    height: $spinner-size;
    text-align: center;
    animation: sk-rotate 2s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: calc($spinner-size / 2 * -1);
    margin-left: calc($spinner-size / 2 * -1);
}

/* Spinner Circle Rotation */
.sp-circle {
    width: $spinner-size;
    height: $spinner-size;
    position: absolute;
    top: 50%;
    left: 45%;
    margin-top: calc($spinner-size / 2 * -1);
    margin-left: calc($spinner-size / 2 * -1);
	border: 4px $white solid;
	border-top: 4px rgba($primary-site-color,1) solid;
	border-radius: 50%;
	-webkit-animation: spCircRot .6s infinite linear;
	animation: spCircRot .6s infinite linear;
}


.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: $white;
    border-radius: 100%;
    animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
}

@keyframes sk-rotate {
    100% { transform: rotate(360deg); }
}

@keyframes sk-bounce {
    0%,
    100% { transform: scale(0); }
    50% { transform: scale(1); }
}


@-webkit-keyframes spCircRot {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(359deg); }
}
@keyframes spCircRot {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}