.video-dialog {
    &.modal-dialog {
        max-width: 700px;
    }
    .modal-header {
        border: 0px;
        padding: 0px;
        height: 0px;
        .close{
            padding: 0;
            margin: 0rem 0rem -1rem auto;
            background-color: #F7F3EF;
            color: #1E1E1E;
            opacity: 1;
            z-index: 1;
            border-radius: 100%;
            width: 24px;
            height: 24px;
        }
    }
    .modal-content {
        max-width: 700px;
    }
    .modal-body {
        height: 394px;
    }
}