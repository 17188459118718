.shop-by-menu-wrapper-css {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: -100%;
    top: 0;
    width: 100%;
    bottom: 0;
    z-index: get-z-index(menu);
    opacity: 0;
    transition: opacity 0.2s ease-out;

    .shop-by-menu {
        background: #fff;
        width: 375px;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        padding: 24px 0;
        position: fixed;
        left: -100%;
        top: 0;
        height: 780px;
        box-sizing: border-box;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
        z-index: 10;
        transition: all 0.4s ease-out;
        opacity: 0;

        .menu-title {
            letter-spacing: -0.96px;
            font-size: 24px;
            margin: 0 0 16px;
            padding: 0 16px;
            @include headingFont();
        }

        > .navbar-nav {
            .nav-link {
                @include primaryFont(700);
                color: var(--skin-menu-color-1-invert);
                font-size: 20px;
                letter-spacing: -0.8px;
                display: flex;
                padding: 16px 16px;
                align-items: center;
                justify-content: space-between;
                border-radius: 0;
                
            }
        }
    }

    .close-menu-css {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: var(--skin-menu-color-2-invert);
    }

    &.open {
        opacity: 1;
        left: 0;
        
        .shop-by-menu {
            left: 0;
            opacity: 1;
        }
    }
}

.shop-by-menu-mobile-wrapper-css {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: -100%;
    top: 0;
    width: 100%;
    bottom: 0;
    z-index: get-z-index(menu);
    opacity: 0;
    transition: opacity 0.2s ease-out;

    .shop-by-menu {
        background: #fff;
        width: 375px;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        padding: 24px 0 0;
        position: fixed;
        left: -100%;
        top: 0;
        height: 90vh;
        box-sizing: border-box;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
        z-index: 10;
        transition: all 0.4s ease-out;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;;
        flex-direction: column;

        .menu-title {
            letter-spacing: -0.96px;
            font-size: 24px;
            margin: 0 0 16px;
            padding: 0 16px;
            @include headingFont();
        }

        .menu-mobile {
            width: 100%;

            > .navbar-nav {
                .nav-link {
                    @include primaryFont(700);
                    color: var(--skin-menu-color-1-invert);
                    font-size: 18px;
                    letter-spacing: -0.72px;
                    display: flex;
                    padding: 8px 16px;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 0;
                    
                }
            }
        }
    }

    .close-menu-css {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: var(--skin-menu-color-2-invert);
    }

    &.open {
        opacity: 1;
        left: 0;
        
        .shop-by-menu {
            left: 0;
            opacity: 1;
        }
    }

    .bottom-menu-floater {
        background-color: var(--skin-menu-color-2-invert);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom-right-radius: 24px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width:100%;

            li {
                width: 50%;

                a {
                    font-size: 14px;
                    letter-spacing: -0.28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--skin-menu-color-1-invert);

                    svg {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

.sub-category-css {
    position: fixed;
    width: 405px;
    left: -100%;
    opacity: 0;
    top: 0;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    padding: 14px 0;
    height: 780px;
    background-color: var(--skin-menu-color-2-invert);
    box-sizing: border-box;
    z-index: 5;
    overflow: hidden;
    padding-left: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    transition: all ease-in-out 0.2s;

    &::-webkit-scrollbar {
        display: none;
    }

    &.open {
        opacity: 1;
        left: 355px;
    }

    .subcategory-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;

        > li {
            

            .css-category-dropdown-open {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            a {
                letter-spacing: -0.88px;
                font-size: 22px;
                margin: 0 0 16px;
                padding: 0 16px;
                color: var(--skin-menu-color-1-invert);
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include headingFont();

                &:first-child {
                    width: 75%;
                }

                &:nth-child(2) {
                    width: 25%;
                    display: block;
                    text-align: right;
                }

                &:hover {
                    background: transparent;
                }

                &.active {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .subcategory-menu-item {
            list-style: none;
            margin: 0;
            padding: 0 0 0 16px;
            display: none;
            overflow: hidden;

            > li {
                a {
                    letter-spacing: -0.32px; 
                    font-size: 16px; 
                    margin: 0 0 16px;
                    padding: 0 16px;
                    color: var(--skin-menu-color-1-invert);
                    @include headingFont();

                    &.active {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }

                .subcategory-menu-item {
                    li {
                        a {
                            font-size: 14px;
                            letter-spacing: -0.19px;
                        }
                    }
                }
            }
        }
    }

    .menu-title {
        letter-spacing: -0.72px; 
        font-size: 18px; 
        margin: 0 0 16px;
        padding: 9px 16px 0;
       
        @include primaryFont(700);

        a {
            display: flex;
            align-items: center;
            color: var(--skin-menu-color-1-invert);
        }
        
        svg {
            margin-right: 8px;
        }
    }

    .close-menu-css {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: #fff;
    }

    @include media-breakpoint-down(md) {
        z-index: get-z-index(menu);
        width: 375px;
        height: 90vh;
        padding-left: 0;

        &.open {
            opacity: 1;
            left: 0;
        }
    }
}

.get-started-css {
    position: fixed;
    left: 0;
    top: -100%;
    height: 100vh;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: get-z-index(menu);
    opacity: 0;
    transition: opacity 0.2s ease-out;

    .get-started-wrapper-css {
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
        padding: 0px 0 40px;
        background: #fff;
        position: fixed;
        top: -100%;
        left: 0;
        right: 0;
        opacity: 0;
        transition: all 0.4s ease-out;
    }

    ul.get-started-gallery {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            width: 24%;
            margin-right: 2%;

            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                position: relative;
                border-radius: 12px;
                overflow: hidden;

                img {
                    width: 100%;
                }

                span {
                    font-size: 14px;
                    letter-spacing: -0.28px;
                    color: var(--skin-menu-color-1-invert);
                    height: 34px;
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 12px;
                    position: absolute;
                    background: #fff;
                    bottom: 18px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    &.open {
        top: 121px;
        opacity: 1;

        .get-started-wrapper-css {
            top: 121px;
            opacity: 1;
        }
    }

    .menu-title {
        letter-spacing: -0.72px; 
        font-size: 18px; 
        margin: 0 0 16px;
        padding: 0 0;
       
        @include primaryFont(700);

        a {
            display: flex;
            align-items: center;
            color: var(--skin-menu-color-1-invert);
        }
        
        svg {
            margin-right: 8px;
        }
    }

    .close-menu-css {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: var(--skin-menu-color-2-invert);
    }

    @include media-breakpoint-down(md) {
        top: 0;
        left: -100%;
        right: auto;
        width: 100%;

        ul.get-started-gallery {
            flex-direction: column;

            li {
                width: 100%;
                margin-right: 0;
                
                margin-bottom: 8px;
                
                a {
                    overflow: hidden;
                    height: 131px;

                    span {
                        transform: none;
                        left: auto;
                        right: 11px;
                        bottom: 8px;
                    }
                }
            }
        }

        .get-started-wrapper-css {
            width: 375px;
            height: 90vh;
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
            border-bottom-left-radius: 0;
            padding-top: 24px;
            top: 0;
            left: -100%;

            .container {
                flex-direction: column;
            }
        }
    
        &.open {
            top: 0;
            left: 0;

            .get-started-wrapper-css {
                top: 0;
                left: 0;
            }
        }
    }
}

.project-resources-css {
    position: fixed;
    left: 0;
    top: -100%;
    height: 100vh;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: get-z-index(menu);
    opacity: 0;
    transition: opacity 0.2s ease-out;

    .project-resources-wrapper-css {
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
        padding: 0px 0 40px;
        background: #fff;
        position: fixed;
        top: -100%;
        left: 0;
        right: 0;
        opacity: 0;
        transition: all 0.4s ease-out;

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .project-resources-links {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        .project-link {
            width: 48%;
            h5 {
                margin: 0 0 12px;
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
    
                li {
                    margin: 0 0 8px;
                    a {
                        letter-spacing: -0.32px;
                        font-size: 16px;
                        color: var(--skin-menu-color-1-invert);
    
                        &:hover {
                            text-decoration: underline;
                            color: var(--skin-link-color-1);
                        }
                    }
                }
            }
        }
    }

    ul.project-resources-gallery {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        li {
            width: 48%;
            margin-right: 2%;

            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                position: relative;
                
                overflow: hidden;

                .image-container {
                    border-radius: 12px;
                    margin-bottom: 10px;
                    overflow: hidden;
                }

                img {
                    width: 100%;
                }

                h6 {
                    font-size: 16px;
                    letter-spacing: -0.32px;
                    color: var(--skin-menu-color-1-invert);
                    margin: 0;
                }
            }
        }
    }

    &.open {
        top: 121px;
        opacity: 1;

        .project-resources-wrapper-css {
            top: 121px;
            opacity: 1;
        }
    }

    .menu-title {
        letter-spacing: -0.72px; 
        font-size: 18px; 
        margin: 0 0 16px;
        padding: 0 0;
       
        @include primaryFont(700);

        a {
            display: flex;
            align-items: center;
            color: var(--skin-menu-color-1-invert);
        }
        
        svg {
            margin-right: 8px;
        }
    }

    .close-menu-css {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: var(--skin-menu-color-2-invert);
    }

    @include media-breakpoint-down(md) {
        top: 0;
        left: -100%;
        right: auto;
        width: 100%;

        ul.project-resources-gallery {
            flex-direction: column;
            width: 100%;;

            li {
                width: 100%;
                margin-right: 0;
                
                margin-bottom: 12px;
                
                a {
                    overflow: hidden;
                    
                    .image-container {
                        height: 131px;
                    }
                }
            }
        }

        .project-resources-wrapper-css {
            width: 375px;
            height: 90vh;
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
            border-bottom-left-radius: 0;
            padding-top: 24px;
            top: 0;
            left: -100%;
            overflow-y:  scroll;

            .container {
                flex-direction: column;
                align-items: stretch;
            }
        }

        .project-resources-links {
            flex-direction: column;
            width: 100%;

            .project-link {
                width: 100%;
                ul {
                    padding-left: 16px;
                    margin-bottom: 24px;
                }
            }
        }
    
        &.open {
            top: 0;
            left: 0;

            .project-resources-wrapper-css {
                top: 0;
                left: 0;
            }
        }
    }
}

.menu-group {
    .navbar-nav {
        li {
            &:not(:first-child) {
                @include media-breakpoint-down(lg) {
                    display: none
                }
            }
        }
    }
}

.main-menu-css {
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background-color: var(--skin-menu-color-2-invert);
        }
    }
}