@import "../variables";

:root {
    /** ==================================================================== **/

    /** Create Custom CSS Variables from "sfra bootstrap override" variables **/

    /** ==================================================================== **/
    --color-white: #{$white};
    --color-black: #{$black};
    --color-blue: #{$blue};
    --color-green: #{$green};
    --color-primary: #{$grey9};
    --color-red: #{$red};
    --color-success: var(--color-green);
    --color-danger: var(--color-red);
    --color-light-blue: #{$light-blue};

    // Consolidated values
    --color-grey1: #{$grey1};
    --color-grey2: #{$grey2};
    --color-grey3: #{$grey3};
    --color-grey4: #{$grey4};
    --color-grey5: #{$grey5};
    --color-grey6: #{$grey6};
    --color-grey7: #{$grey7};
    --color-grey8: #{$grey8};
    --color-grey9: #{$grey9};
    --color-grey10: #{$grey10};
    --color-grey-transparent-1: rgba(0, 0, 0, 65%);
    --color-grey-transparent-2: rgba(0, 0, 0, 25%);
    --color-light-gray: var(--color-grey1);
    --color-slightly-darker-gray: var(--color-grey4);
    --color-dark-gray: var(--color-grey6);
    --color-darker-gray: var(--color-grey7);
    --color-horizontal-rule-grey: var(--color-grey3);
    --color-product-number-grey: var(--color-grey3);
    --color-horizontal-border-grey: var(--color-grey4);
    --color-menu-link: var(--color-grey6);
    --color-close-menu-bg: var(--color-grey2);
    --color-link-color: var(--color-dark-gray);
    --color-hr-border-color: var(--color-grey3);

    // Fonts
    --malroy-light: 'Mallory Light', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;;
    --malroy-book: 'Mallory Book', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;;
    --malroy-bold: 'Mallory Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;;

    /** ==================================================================== **/

    /** Define skin named variables here                                    **/

    /** ==================================================================== **/
    --skin-background-color-1: var(--color-gray1);
    --skin-border-color-1: var(--color-white);
    --skin-main-text-color-1: var(--color-gray9);
    --skin-main-text-color-2: var(--color-gray10);
    --skin-menu-color-1: #444;
    --skin-menu-color-1-invert: var(--color-grey9);
    --skin-menu-color-2-invert: #{get-brand-color(mist)};
    --skin-menu-color-2: #222;
    --skin-link-color-1: var(--color-primary);
    --skin-link-color-hover-1: #6E224E;
    --skin-link-color-2: var(--color-white);
    --skin-link-color-3: #{get-brand-color(sageGreen)};
    --skin-primary-color-1: var(--color-primary);
    --skin-primary-color-2: #{get-brand-color(sageGreen)};
    --skin-primary-color-3: #{get-brand-color(mist)};
    --skin-primary-color-invert-1: #42142F;
    --skin-primary-color-invert-2: #{get-brand-color(sageGreen60)};
    --skin-primary-color-invert-3: #FAF8F5;
    --skin-selectbox-background-color-1: var(--color-white);
    --skin-selectbox-text-color-1: var(--color-grey5);
    --skin-banner-background-color-1: #444;
    --skin-banner-background-color-2: #222;
    --skin-banner-text-color-1: var(--color-white);
    --skin-heading-color-1: var(--color-primary);
    --skin-heading-color-1-invert: var(--color-white);
    --skin-price-1: var(--color-gray7);
    --skin-header-font: 'Gotu', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;;
    --skin-body-font: var( --malroy-light);
    --skin-body-font-book: var( --malroy-book);
    --skin-body-font-bold: var( --malroy-bold);
}
