.icon-cart {
    background: url("../../images/icons/bag-white.svg") no-repeat;
}
.icon-edit {
    background: url("../../images/icons/edit.svg") no-repeat;
}
.icon-add-all {
    background: url("../../images/icons/add-all.svg") no-repeat;
}

.icon-print {
    background: url("../../images/icons/print.svg") no-repeat;
}

.icon-cart-quickview {
    background: url("../../images/icons/bag-white.svg") no-repeat;

    @include media-breakpoint-down(md) {
        background: url("../../images/icons/quick-view.svg") no-repeat;
    }
}