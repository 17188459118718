select.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: flex;
    padding: 9px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid $grey3;
    background: $svg-arrow;
    background-color: $white;
    height: auto;
    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
        border: 1px solid $grey3;
    }
}