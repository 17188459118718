.homepage-product-listing {

    .swiper-button-prev,
    .swiper-button-next {
        background: #fff;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-top: -30px;

        &::after {
            display: none;
        }

        &.swiper-button-disabled {
            opacity: 0;
        }
    }

    .swiper-slide {
        @include media-breakpoint-up(xxl) {
            max-width: 100%;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 305px;
            width: 100%;
        }
    }

    .swiper-wrapper {
        height: auto;
    }

    &.search-page-listings {
        .section-title {
            text-align: center;
            margin-bottom: 20px;

            @include media-breakpoint-down(sm) {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }
}