.search-bar-wrapper {
	position: fixed;
	right: -150%;
	top: 0;
	bottom: 0;
	height: 100vh;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 4000;
	opacity: 0;
	transition: opacity 0.2s ease-out;

	.search-bar {
		border-top-left-radius: 24px;
		border-bottom-left-radius: 24px;
		background: #fff;
		position: fixed;
		right: -150%;
		top: 0;
		bottom: 0;
		height: 100vh;
		width: 406px;
		opacity: 0;
		padding: 16px 24px;
		transition: all 0.4s ease-out;
		overflow-y: scroll;

		@include media-breakpoint-down(xs) {
			width: 100%;
			position: relative;
			border-radius: 0;
		}

		.search-text-area {
			display: flex;
			padding: 8px 0px;
			align-items: center;
			gap: 8px;
			flex: 1 0 0;
			border-bottom: 1px solid #ddd;

			.search-field {
				border: none;
				padding-left: 0;
				box-shadow: none;
			}

			.clear-input {
				color: #a4a4a4;
				font-size: 12px;
				line-height: 130%;
				letter-spacing: -0.24px;
				text-decoration-line: underline;
			}

			.search-icon {
				border: 0;
				box-shadow: none;
				padding: 0;
				background: transparent;
			}
		}
	}

	&.open {
		right: 0;
		opacity: 1;

		.search-bar {
			right: 0;
			opacity: 1;
		}
	}

	.suggestions-wrapper {
		overflow: hidden;

		.header {
			h2 {
				color: #1e1e1e;
				font-family: var(--skin-body-font-book);
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 120%;
				letter-spacing: -0.72px;
			}
		}

		.items {
			.item {
				list-style: none;
				position: relative;

				.name {
					color: #1e1e1e;
					@include primaryFont(700);
					font-size: 16px;
					line-height: 130%;
					letter-spacing: -0.32px;
				}

				.price {
					overflow: hidden;
					color: #1e1e1e;
					text-overflow: ellipsis;

					/* function/text-price */
					font-family: var(--skin-body-font);
					font-size: 16px;
					letter-spacing: -0.32px;
					&:before {
						content: "From";
						font-size: 12px;
						padding-right: 5px;
					}
				}

				.product-suggestion-action {
					
				}
			}
		}

		// .product-suggestion-action {
		// 	display: none;
		// }

		.suggestion-text {
			margin-bottom: 12px;
			a {
				color: #1e1e1e;
				@include primaryFont(700);
				font-size: 16px;
				line-height: 130%;
				letter-spacing: -0.32px;
				display: block;
			}
		}

		.view-all-text {
			a {
				color: #6e224e;
				font-family: var(--skin-body-font);
				font-size: 18px;
				font-weight: 400;
				line-height: 120%;
				letter-spacing: -0.72px;
				text-decoration-line: underline;
			}
		}

		.suggestion-img {
			border-radius: 8px;
			margin-right: 12px;
		}
	}

	@include media-breakpoint-up(lg) {
		z-index: get-z-index(menu);
	}
}
