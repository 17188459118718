::placeholder {
    color: $grey5;
    font-size: 16px;
    font-family: var(--skin-body-font);
}

.count-car {
    color: $grey5;
    font-size: 16px;
    font-family: var(--skin-body-font);
    margin-top: 16px;
    margin-bottom: 0;
}

.form-group {
    .form-control {
        border-radius: toRem(6);
        border: 1px solid $grey3;
        background: white;
        height: toRem(43);
        
        &:focus {
            border: 1px solid $grey6;
            outline: 0;
            box-shadow: none;
        }
        &::placeholder {
            color: $grey5;
            font-size: 16px;
            font-family: var(--skin-body-font);
        }
    }

    .form-control-label {
        color: $grey9;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        @include primaryFont(350);
    }

    &.required {
        .form-control-label {
            &::after {
                content: '*';
                color: $red;
            }
        }
    }

    &.custom-select-box {
        position: relative;
        
        &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6663 6.5L7.99967 11.1667L3.33301 6.5' stroke='%230E0E0E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            width: 16px;
            height: 17px;
            position: absolute;
            right: 10px;
            top: 43px;
            z-index: 1;
        }
        
        .form-control {
            position: relative;
            z-index: 2;
            background: transparent;
        }
    }

    &.custom-select-box-qty {
        position: relative;
        background: #fff;
        border: 1px solid #DDDDDD;
        border-radius: 6px;
        &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 6L8 2.5L11.5 6H4.5Z' fill='%231E1E1E'/%3E%3Cpath d='M11.5 10L8 13.5L4.5 10L11.5 10Z' fill='%231E1E1E'/%3E%3C/svg%3E");
            width: 16px;
            height: 16px;
            position: absolute;
            right: 12px;
            top: 13px;
            z-index: 1;
        }

        .custom-select {
            padding: 9px 12px;
            background: transparent;
            border: 0;
            position: relative;
            z-index: 2;
        }

        &.smallBox {
            &::after {
                top: 8px;
            }

            .custom-select {
                padding: 4px 12px;
            }
        }
    }

    .helper {
        color: $grey9;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        @include primaryFont(350);
        margin-top: 5px;
    }

    .info-icon {
        position: absolute;
        right: 15px;
        top: 0;
        display: block;
        padding: 10px;

        .tooltip {
            max-width: 342px;
            min-width: 280px;
            width: auto;
            bottom: -8px;
        }

        @include media-breakpoint-down(lg) {
            display: block;

            .tooltip {
                bottom: 32px;
                left: auto;
                right: calc(100% - 16px);
            }
        }
    }
}

.giftcert-wrapper{
    margin-top: 16px;
    label{
        display: none;
    }
    .form-control {
        border-radius: 6px;
        border: 1px solid $grey3;
        height: 2.625rem;
        line-height: 160%;
    }
    textarea {
        height: 88px !important;
    }
}

.custom-control {

    .custom-control-label,
    p {
        color: $grey9;
        font-size: 14px;
        line-height: 130%;
        /* 18.2px */
        letter-spacing: -0.28px;

        &::before {
            background: white;
            top: -1px;
        }
    }

    .custom-control-input:checked {
        ~.custom-control-label::after {
            top: 0;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        }

        ~.custom-control-label::before {
            background-color: black;
            border-color: black;
        }
    }
}

.custom-radio {
    position: relative;
    margin-left: 10px;
    padding: 0 0 0 30px;
    cursor: pointer;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
            background-color: #fff;

            &:after {
                display: block;
                background: #000;
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #A4A4A4;

        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 3px;
            left: 3px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: white;
        }
    }
}

.shipping-form,
.billing-form {
    .form-group {
        .form-control-label {
            text-transform: none;
            font-size: 14px;
            letter-spacing: -0.28px;
            @include primaryFont(400);
        }
    }
}


.manual-quantity {
    position: relative;
    background: #fff;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    input {
        text-indent: 0;
        padding-right: 30px;
        border: 0;
        background: transparent;
        outline: 0;
        box-shadow: none;
        &:focus,
        &:active {
            outline: 0;
            box-shadow: none;
        }
    }
    .manual-quantity-indicator {
        position: absolute;
        right: 0;
        width: 28px;
        height: 27px;
        display: block;
        padding-left: 5px;
        padding-right: 15px;

        &.disabled {
            opacity: 0.5;
        }
        
        img {
            width: 100%;
            display: block;

            &:before {
                display: none;
            }
        }

        &.manual-quantity-plus {
            // top: 18px;
            top: 0;
            padding-bottom: 2px;
            padding-top: 20px;
        }

        &.manual-quantity-minus {
            // bottom: 17px;
            bottom: 0;
            padding-top: 2px;
            padding-bottom: 20px;
        }
    }
}
    
