.navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-link {
        font-size: 14px;
        letter-spacing: -0.28px;
        border-radius: 6px;
        margin-right: 4px;
        font-family: var(--skin-body-font-book);
        &:hover,
        &.active {
            @include primaryFont(700);
            background-color: var(--skin-menu-color-2-invert) !important;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 16px 0;
    }

    .mobile-menu-trigger-css {
        padding: 10px;
        margin-right: 8px;
    }
}

.navbar {
    padding: 25px 1rem;
}

.user {
    margin-right: 16px;
    @include media-breakpoint-down(md) {
        margin-left: 16px;
    }
    a {
        font-size: 12px;
        letter-spacing: -0.24px;
        display: flex;
        align-items: center;
        color: var(--skin-menu-color-1-invert);
    }
}

.search-mobile {
    margin-bottom: 26px;
}

.site-search {
    position: relative;
    margin-right: 12px;
    margin-top: 27px;
    margin-bottom: 26px;

    .form-control {
        &.search-field {
            border-radius: 100px;
            border: 1px solid rgba(30, 30, 30, 0.40);
            height: 34px;
            color: var(--color-grey6);
            font-size: 14px;
            letter-spacing: -0.28px;
            width: 390px;
            display: flex;
            align-items: center;
            padding: 4px 10px 0 40px;

            @include placeholder() {
                color: var(--color-grey6);
                font-size: 14px;
                letter-spacing: -0.28px;
            }
        }
    }

    button {
        background: none;
        border: 0;
        box-shadow: none;
        outline: 0;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 0 16px;
    }

    @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;

        .form-control {
            margin-bottom: 16px;
            &.search-field {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}

.logo-menu-section {
    display: flex;
    align-items: center;
}

.search-account-section {
    display: flex;
    align-items: center;
}

.search-mobile-icon {
    display: none;
}

.outer-banner {
    background: get-brand-color(lightSage);
    padding: 16px 0;
    .swiper-slide {
        @include media-breakpoint-up(md) {
            max-width: 33%;
        }
    }
    .outerBannerItem {
        border-right: 1px solid $grey4;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        

        .link-title {
            letter-spacing: -0.28px;
            font-size: 14px; 
            color: var(--skin-menu-color-1-invert);
            text-align: center;
            @include primaryFont(700);
            margin: 0;
            a {
                color: var(--skin-menu-color-1-invert);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .link-description {
            letter-spacing: -0.28px;
            font-size: 14px;
            color: var(--color-menu-link);
            text-align: center;
            margin: 0;
        }
    }

    .swiper-slide {
        &:nth-child(3) {
            .outerBannerItem {
                border-right: 0;
            }
        }
    }

    .swiper-pagination-bullet-active {
        background-color: #000;
    }

    .swiper-wrapper {
        height: auto;
    }

    @include media-breakpoint-down(md) {
        padding: 16px 0 32px;
        position: relative;

        .outerBannerItem {
            border-right: 0;

            .link-title {
                a {
                    text-decoration: underline;
                }
            }
        }

        .swiper-wrapper {
            align-items: center;
        }
    }
}

.top-ribbon {
    min-height: 34px;
    position: sticky;
    z-index: 100;
    top: 0;
    background-color: var(--skin-menu-color-2-invert);
    width: 100%;
    @include media-breakpoint-down(md) {
        position: static;
    }

    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .ribbon-links {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 17px;
            top: 0;
            bottom: 0;
            height: 34px;

            li {
                margin-left: 16px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: var(--skin-menu-color-1-invert);
                    
                    svg {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .topRibbon-Swiper-css {
        width: 50%;
        position: relative;

        @include media-breakpoint-down(md) {
            width: 95%;
        }
    }

    .swiper-slide { 
        min-height: 34px;
        align-items: center;
        justify-content: center;
        color: var(--skin-menu-color-1-invert);
        font-size: 14px;
        letter-spacing: -0.28px;
        text-align: center;
        display: flex;
        flex-flow: wrap;

        a {
            color: var(--skin-menu-color-1-invert);
            text-decoration: underline;
            margin: 0 3px;
        }

        strong {
            margin: 0 3px;
        }

        @include media-breakpoint-down(md) {
            font-size: 12px;
            letter-spacing: -0.24px;
            padding: 4px 0;
        }
    }

    .swiper-button-prev {
        left: -14px;
    }

    .swiper-button-next {
        right: -14px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 12px;
        height: 12px;
        margin-top: -6px;

        &::after {
            display: none;
        }
    }
}

.site-main-header {
    position: sticky;
    left: 0;
    top: 34px;
    z-index: get-z-index(header);
    background: $white;
    @include media-breakpoint-down(md) {
        top: -1px;
    }
}

.is-sticky {
    .search-mobile-icon {
       @include media-breakpoint-down(md) {
            display: block;
        }
    }
    .search-mobile {
        @include media-breakpoint-down(sm) {
            display: none;
        }  
    }
    .sticky-filter {
        @include media-breakpoint-down(sm) {
            padding: 2px 10px 0;
            position: sticky;
            top: 4.70rem;
            z-index: 100;
            background: $white;
        }
    }

}

.cart-page-indicator {
    .outer-banner {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

@keyframes stickySlideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}