.shadow {
    box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.16);

    &.xl {
        box-shadow: 0px 24px 46px -6px rgba(0, 0, 0, 0.32);
    }

    &.lg {
        box-shadow: 0px 12px 32px -4px rgba(0, 0, 0, 0.24);
    }

    &.sm {
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
    }

    &.xs {
        box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.12);
    }
}