// Clearfix
@mixin clearfix {
	&::after {
    	clear: both;
    	content: '';
    	display: table;
  	}
}

//Placeholders
//Usage:
//@include placeholder;
@mixin placeholder {
    // sass-lint:disable no-vendor-prefixes
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
  &::placeholder 				{@content}
};

// Truncating Text
@mixin truncateText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// function px to rem
@function toRem($value) {
    $remValue: ($value / 16) + rem;
    @return $remValue;
}

// Get brand colors based on their name from figma
// plum - sageGreen60 - sageGreen - sage - mist - Check variables file color reference
// Define a function to retrieve brand colors
@function get-brand-color($color-name) {
    @if map-has-key($brand-colors, $color-name) {
        @return map-get($brand-colors, $color-name);
    } @else {
        @error "Unknown brand color: #{$color-name}.";
        @return null;
    }
}

// Get feedback colors based on their name from figma
// negative - notice - positive - informative - Check variables file color reference
// Define a function to retrieve brand colors
@function get-feedback-color($color-name) {
    @if map-has-key($feedback-colors, $color-name) {
        @return map-get($feedback-colors, $color-name);
    } @else {
        @error "Unknown brand color: #{$color-name}.";
        @return null;
    }
}

// Get z-index based on their name 
// Define a function to retrieve zindex
@function get-z-index($index) {
    @if map-has-key($zindex, $index) {
        @return map-get($zindex, $index);
    } @else {
        @error "Unknown brand color: #{$index}.";
        @return null;
    }
}

// Mixin for base font weights based on their value 
@mixin primaryFont($size) {
    @if $size == 400 {
		font-family: var(--skin-body-font-book);
        font-weight: 400;
    } @else if ($size == 700) {
		font-family: var(--skin-body-font-bold);
		font-weight: 400;
	} @else {
		font-family: var(--skin-body-font);
        font-weight: 400;
    }
}

// Mixin for base font weights based on their value 
@mixin headingFont() {
    font-family: var(--skin-header-font);
    font-weight: 400;
}