html:has(body.modal-open) {
    overflow: hidden;
}

html,
body {
    &.sidepanel-open {
        overflow: hidden;
    } 
}

.modal-backdrop {
    z-index: 4000;
}

.modal {
    z-index: 4001;
    &.top-right {
        padding: 0!important;
        &.show {
            .modal-dialog {
                -webkit-transform: none;
                transform: none;
            }
        }
        .modal-dialog {
            -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
            max-width: toRem(450);
            margin: 0 0 0 auto;
            -webkit-border-top-left-radius: 1.5rem;
            -webkit-border-bottom-left-radius: 1.5rem;
            -moz-border-radius-topleft: 1.5rem;
            -moz-border-radius-bottomleft: 1.5rem;
            border-top-left-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
            overflow: hidden;
        }
        .modal-header {
            margin: 1.25rem 1.5rem 0.5rem 1.5rem;
            padding: 0;
            border: 0;
            position: relative;
            .modal-title {
                color: $grey9;
                font-size: 1.125rem;
                line-height: 120%;
                letter-spacing: -0.045rem;
            }
        }
        .modal-body {
            padding: 0;
            overflow: hidden;
        }

        &.fade {
            .quick-view-dialog {
                .modal-body {
                    padding: 0;
                    overflow: auto;
                }
            }
        }

        .modal-content {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        button.close {
            right: 12px;
            top: 10px;
        }
    }

    &#quickViewModal {
        .choona  {
            display: none;
        }
    }

    &#saveForLaterModal,
    &#shareCartModal {
        .modal-header {
            margin: 0 0 28px;
        }

        button.close {
            top: -5px;
            right: 0;
        }
    }

    button.close {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: toRem(100);
        background: $mist;
        position: absolute;
        right: 0;
        top: 0;
        width: 2rem;
        height: 2rem;
        z-index: 2;
        opacity: 1;
        position: absolute;
        right: 20px;
        top: 20px;
        &:focus {
            outline: 0;
        }
    }

    .heading {
        @include primaryFont(400);
    }

    .modal-header {
        align-items: center;
        padding: 0;
        border: none;
        &.addressChange-confirmation-header {
            display:block;
        }

        button.close {
            top: 11px;
        }
        
        .modal-title {
            font-size: 18px;
            letter-spacing: -0.72px;
            @include primaryFont(400);

            h1 {
                color: $grey9;
                font-size: 18px;
                @include primaryFont(400);
                line-height: 21.6px;
                letter-spacing: -0.72px;
                margin: 0;
            }
        }
    }

    &.rounded {
        .modal-content {
            border-radius: 24px;
        }

        .modal-header {
            border-radius: 0;
            border-bottom: 0;
            padding-bottom: 0;
            &.delete-coupon-confirmation-header {
                button.close {
                    top: 11px;
                }
                .modal-title {
                    font-size: 18px;
                    letter-spacing: -0.72px;
                    @include primaryFont(400);
                }
            }
        }
    }

    .modal-content {
        border-radius: 24px;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
        padding: 24px 20px;
        border: 0;

        &.notRounded {
            border-radius: 24px 0 0 24px;
        }
    }

    &#chooseBonusProductModal {
        .modal-dialog {
            max-width: 876px;
        }

        .modal-header {
            flex-direction: column;

            .modal-title {
                font-size: 48px;
                letter-spacing: -2.88px;
                margin: 0 0 12px;
                line-height: 100%;
                text-align: center;
                @include headingFont();

                @include media-breakpoint-down(sm) {
                    font-size: 25px;
                    line-height: 110%;
                    letter-spacing: -1.2px;
                }
            }

            .modal-description {
                font-size: 16px;
                letter-spacing: -0.32px;
                line-height: 130%;
                @include primaryFont(350);
                text-align: center;
                margin: 0;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 16px;
                }
            }
        }

        .modal-body {
            padding: 0;

            .select-bonus-product {
                &.btn-primary {
                    height: 37px;
                    font-size: 14px;
                    letter-spacing: -0.28px;
                }
            }

            .product-quickview {
                position: relative;
            }
        }

        .choose-bonus-cta {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-top: 1px solid #dee2e6;
            padding: 18px 0;

            .btn {
                width: 100%;
                max-width: 320px;
                margin-bottom: 10px;
                @include primaryFont(400);

                &:last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                }
            }
        }
        .bonus-exceeded-message {
            display: none;
            border-radius: 6px;
            padding: 10px;
            text-align: center;
            font-size: 14px;
            @include primaryFont(400);
            margin-top: 20px;
            &.alert-danger {
                display: block;
            }
        }

        .btn-primary {
            &.disabled,
            &:disabled {
                opacity: 1;
            }

        }

        .product-tags-container {
            @include media-breakpoint-down(md) {
                position: static;
                margin-bottom: 12px;
            }
        }
    }

    &#removeProductModal {
        .modal-header {
            margin-left: 0;
            margin-right: 0;
        }

        button.close {
            top: -9px;
        }
    }

    @include media-breakpoint-down(md) {
        .modal-header {
            &.addressChange-confirmation-header {
    
                h4 {
                    font-size: 24px;
                }
    
                h6 {
                    font-size: 16px;
                }
            }
        }
        .modal-body {
            &.addressChange-confirmation-body {
                .entered-address {
                    border-right: solid grey;
                }
    
                h6 {
                    font-size: 14px;
                }
    
                p {
                    font-size: 14px;
                }
            }
        }
    }
}

.modal.bulkDetails {
    z-index: 4002;
}